export const ONE_PIXEL_IN_MM = 0.264_583_333_333_33;

export const DEFAULT_TEMPLATE = {
  name: 'Nueva plantilla',
  options: {
    items: [getDefaultItemConfig('box')],
    width: null,
    height: null,
  },
};

export const FORMATS = [
  {
    value: 'GS1128',
  },
  {
    value: 'CODE39',
    description:
      'CODE39 es un tipo de código de barras antiguo que puede codificar números, letras mayúsculas y varios caracteres especiales (-, ., $, /, +, % y espacio). Ha sido un tipo de código de barras común en el pasado, pero se recomienda CODE128 si no es por razones heredadas.',
  },
  {
    value: 'CODE128',
    description:
      'Tiene soporte para los 128 caracteres ASCII pero también codifica números de manera eficiente. Tiene tres modos (A/B/C) pero puede cambiar entre ellos en cualquier momento.',
  },
  {
    value: 'CODE128A',
    description:
      'Tiene soporte para los 128 caracteres ASCII pero también codifica números de manera eficiente. Tiene tres modos (A/B/C) pero puede cambiar entre ellos en cualquier momento.',
  },
  {
    value: 'CODE128B',
    description:
      'Tiene soporte para los 128 caracteres ASCII pero también codifica números de manera eficiente. Tiene tres modos (A/B/C) pero puede cambiar entre ellos en cualquier momento.',
  },
  {
    value: 'CODE128C',
    description:
      'Tiene soporte para los 128 caracteres ASCII pero también codifica números de manera eficiente. Tiene tres modos (A/B/C) pero puede cambiar entre ellos en cualquier momento.',
  },
  {
    value: 'EAN13',
    description:
      'EAN viene en una variedad de formas, la más utilizada es EAN-13 (GTIN-13) que se usa en todo el mundo para marcar la identidad de los productos.',
  },
  { value: 'EAN8' },
  { value: 'EAN5', description: 'EAN-5 is addon barcodes an is always used combined with EAN-13 or EAN-8.' },
  { value: 'EAN2', description: 'EAN-2 is addon barcodes an is always used combined with EAN-13 or EAN-8.' },
  { value: 'UPC' },
  {
    value: 'ITF14',
    description:
      'ITF-14 (Interleaved Two of Five) es la implementación GS1 de un código de barras Interleaved 2 of 5 para codificar un Número Global de Artículo Comercial. Los símbolos ITF-14 generalmente se usan en los niveles de empaque de un producto, como una caja de 24 latas de sopa. El ITF-14 siempre codificará 14 dígitos. El último dígito de un código de barras ITF-14 es una suma de verificación. Normalmente se incluye, pero se puede calcularlo automáticamente si se omite.',
  },
  { value: 'ITF' },
  {
    value: 'MSI',
    description:
      'MSI o Modified Plessey es un código de barras desarrollado por MSI Data Corporation y se usa principalmente para control de inventario, marcado de contenedores de almacenamiento y estantes en entornos de almacén. Admite dígitos 0-9.',
  },
  { value: 'MSI10' },
  { value: 'MSI11' },
  { value: 'MSI1010' },
  { value: 'MSI1110' },
  {
    value: 'pharmacode',
    description:
      'Pharmacode es un código de barras utilizado en la industria farmacéutica. Puede codificar los números 3 a 131070.',
  },
  {
    value: 'codabar',
    description:
      'Codabar es un tipo de código de barras antiguo que puede codificar números y varios caracteres especiales (–, $, :, /, +, .). Puede establecer los caracteres de inicio y fin en A, B, C o D, pero si no se define ningún carácter de inicio y fin, se utilizará A.',
  },
];

export function getDefaultItemConfig(itemType) {
  const config = {
    text: { options: { text: 'Click para editar', style: { marginTop: 0, marginBottom: 0 } } },
    box: { options: { style: { display: 'flex', flexDirection: 'column' }, items: [] } },
    image: {
      options: {
        src: 'https://www.laberit.com/wp-content/webp-express/webp-images/doc-root/wp-content/uploads/2022/01/cropped-A-con-virgulilla-300x300.png.webp',
        alt: '',
        crossOrigin: null,
        height: 64,
        width: 64,
        style: {},
      },
    },
    barcode: {
      barcodeOptions: {
        format: 'CODE128',
        mod43: false,
        flat: false,
        ean128: false,
        displayValue: true,
        height: 64,
        width: 2,
        textAlign: 'center',
        textPosition: 'bottom',
        fontSize: 20,
        background: '#ffffff',
        lineColor: '#000000',
      },
      options: {
        style: {},
      },
    },
    qr: {
      options: {
        style: {},
      },
    },
  };
  return { itemType, ...config[itemType] };
}
