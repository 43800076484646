import { NumberBox, SelectBox } from 'devextreme-react';
import { useEditor } from '.';

export const Size = () => {
  const { size, template, update } = useEditor();
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          alignSelf: 'center',
          padding: 8,
        }}
      >
        <SelectBox
          dataSource={[
            {
              value: 'sans-serif',
              label: 'Sans serif',
            },
            {
              value: 'monospace',
              label: 'Monospace',
            },
            {
              value: 'Times New Roman',
              label: 'Times New Roman',
            },
          ]}
          valueExpr={'value'}
          displayExpr={'label'}
          value={template.options.style?.fontFamily}
          label="Fuente"
          onValueChange={(value) => update('options.style.fontFamily', value)}
        />
        <NumberBox
          label="Base (mm)"
          value={template.options.width}
          onValueChange={(value) => update('options.width', value)}
        />
        <NumberBox
          label="Altura (mm)"
          value={template.options.height}
          onValueChange={(value) => update('options.height', value)}
        />
        <div>
          Tamaño actual: {size.width}mm x {size.height}mm
        </div>
      </div>
    </>
  );
};
